<template>
    <div>
    <el-form :inline="false" label-width="100px">
        <el-form-item label="项目编号:">
            {{callData.ExamProjectNumber}}
        </el-form-item>
        <el-form-item label="项目名称:">
            {{callData.ExamProjectName}}
        </el-form-item>
        <el-form-item label="项目简介:">
            {{callData.ExamProjectNotes}}
        </el-form-item>
        <el-form-item label="考试流程:">
            {{callData.ExamProcedure}}
        </el-form-item>
        <el-form-item label="报名开始时间:">
            {{callData.EnrollBeginTime}}
        </el-form-item>
        <el-form-item label="报名截止时间:">
            {{callData.EnrollEndTime}}
        </el-form-item>
        <el-form-item label="考试开始时间:">
            {{callData.ExamBeginTime}}
        </el-form-item>
        <el-form-item label="考试结束时间:">
            {{callData.ExamEndTime}}
        </el-form-item>
        <el-form-item label="考试地点:">
            {{callData.ExamPlace}}
        </el-form-item>
        <el-form-item label="试卷名称:">
            {{callData.ExamPaperName}}
        </el-form-item>
        <el-form-item label="证书名称:">
            {{callData.CertificateDesignName}}
        </el-form-item>
        <el-form-item label="考试费用:">
            {{callData.ExamPlace}}
        </el-form-item>
    </el-form>
    </div>
</template>

<script>
export default {
    name: "public",
    props: ["dataObj"], //接受父组件传值
    data() {
        return{
            callData: '',
            optionJudge: '',
            answerArr: []
        }
    },
    watch: {
        'dataObj': function(o){
            this.callData = o
            this.callData.EnrollBeginTime = this.format(this.gettime(this.rTime(new Date(this.callData.EnrollBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
            this.callData.EnrollEndTime = this.format(this.gettime(this.rTime(new Date(this.callData.EnrollEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
            this.callData.ExamBeginTime = this.format(this.gettime(this.rTime(new Date(this.callData.ExamBeginTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
            this.callData.ExamEndTime = this.format(this.gettime(this.rTime(new Date(this.callData.ExamEndTime), "yyyy-MM-dd")),'yyyy-MM-dd HH:mm:ss')
        }
    },
    methods:{
        // 时间转换
        gettime(startTime){
            var starttimeHaoMiao = (new Date(startTime)).getTime() + 8 * 60 * 60 * 1000
            return starttimeHaoMiao
        },
        format(time, format){
            var t = new Date(time);
            var tf = function(i){return (i < 10 ? '0' : '') + i};
            return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a){
                switch(a){
                    case 'yyyy':
                        return tf(t.getFullYear());
                        break;
                    case 'MM':
                        return tf(t.getMonth() + 1);
                        break;
                    case 'mm':
                        return tf(t.getMinutes());
                        break;
                    case 'dd':
                        return tf(t.getDate());
                        break;
                    case 'HH':
                        return tf(t.getHours());
                        break;
                    case 'ss':
                        return tf(t.getSeconds());
                        break;
                }
            })
        },
         rTime(date) {
            var json_date = new Date(date).toJSON();
            return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
        }
    },
    mounted() {
    }
}
</script>

<style lang="stylus" scoped>
    .answer>>>.el-radio__input.is-disabled+span.el-radio__label{
        color #000!important
    }
    .answer>>>.el-checkbox__input.is-disabled+span.el-checkbox__label{
        color #000!important
    }
</style>